body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen{
  width:100%;
  height:100%;
}

.initPic{  
  display: block;
  object-fit: cover;
  margin-left: auto;
  margin-right:auto;
  height: 100%;
}

.img1{
  opacity: 1;
  z-index: 1;
}

.img2{
  opacity: 1;
  z-index: 1;
}

.logo{
  position: absolute;
  z-index: 9999;
  top: -200px;
  right: 0px;
}

.banner {
  position: absolute;
  display: block;
  width: 80%;
  height: 50px;
  top: 85%;
  margin-left: 10%;
  margin-right: 10%;
  background: black;
  z-index: 9999;
  border-radius: 25px;
  border: 1px solid white;
}

.banner h1{
  line-height: 0px;
}

.infos{
  color: orange;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  z-index:9999;
}

.loading {
  background: #333;
  border: 1px solid white;
  border-radius: 10px;
  position: absolute;
  width: 40%;
  height: 40%;
  padding: 10px;
  top: 30%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  z-index:9998;
}